<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <h1>Create Email Template</h1>
  <div class="p-grid">
    <div class="p-col-12 p-sm-6">
      <EmailForm @submitted="createEmailTemplate" />
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import EmailForm from '@/components/EmailForm'
import { firestore } from '@/services/firebase'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default {
  components: { EmailForm, BaseBreadcrumb },
  setup() {
    const router = useRouter()
    const breadcrumbs = [
      { label: 'Email Templates', to: '/emails' },
      { label: 'Create' }
    ]

    function createEmailTemplate(formData) {
      firestore
        .collection('handlebar')
        .doc(formData.name)
        .set(formData)
        .then(() => {
          router.push({ name: 'EmailIndex' })
        })
    }

    return { createEmailTemplate, breadcrumbs }
  }
}
/*
 * https://medium.com/@willbeh/firebase-extensions-trigger-email-custom-template-with-handlebar-7d15e56be72c
 * https://github.com/firebase/extensions/blob/master/firestore-send-email/POSTINSTALL.md
 */
</script>
